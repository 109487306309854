import React from 'react'

import Map from '../Map/Map'
import ComponentAnimation from '../ComponentAnimation/ComponentAnimation'

export default function ContactInfo () {

	return (
		<section className="contact-info">
			<ComponentAnimation>
				<div className="contact-info__wrapper wrapper">
					<div className="contact-info__container">
						<div className="contact-info__info-container">
							<h3 className="contact-info__header">9 - 5PM Mon - Fri</h3>
							<span>Opening Hours</span>
						</div>

						<div className="contact-info__info-container">
							<h3 className="contact-info__header">
								<a href="mailto:info@theblossomcharity.co.uk" className="contact-info__link">info@theblossomcharity.co.uk</a>
							</h3>
							<span>Email Address</span>
						</div>

						<div className="contact-info__info-container">
							<h3 className="contact-info__header">
							<a href="tel:01379678483" className="contact-info__link">01379 678483</a>
							</h3>
							<span>Telephone</span>
						</div>

						<div className="contact-info__info-container">
							<h3 className="contact-info__header">13 Castle Street</h3>
							<h3 className="contact-info__header">Eye, Suffolk</h3>
							<h3 className="contact-info__header">IP23 7AN</h3>
							<span>Address</span>
						</div>
					</div>

					<Map />
				</div>
			</ComponentAnimation>
		</section>
		
	)
}

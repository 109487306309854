import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'
import ContactInfo from '../components/ContactInfo/ContactInfo'
import DynamicZone from '../components/DynamicZone/DynamicZone'

export default function ContactUs({ data: { strapi: { contactPage } }, location }) {

	return (
		<>
			<SEO title="Contact Us" description={ contactPage.seoDescription } pathname={ location.pathname } />
			<h1 className="page-title" >Contact Us</h1>

			{ contactPage && contactPage.content &&
				<DynamicZone components={ contactPage.content } />
			}

			<ContactInfo />
		</>
	)
}

export const query = graphql`
	query conatctUsQuery {
		strapi {
			contactPage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentCommonCallToActionBanner {
						id
						title
						description
						link
						linkLabel
						isExternalLink
						link2 
						linkLabel2
						isExternalLink2
						link3 
						linkLabel3 
						isExternalLink3
					}
					... on StrapiQuery_ComponentContactAboutTheHexagon {
						id
						hexagonTitle
						hexagonDescription 
						hexagonImages {
							id
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 750, maxHeight: 750) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
				}
			}
		}
	}
`